import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  FiHome,
  FiPackage,
  FiCalendar,
  FiSettings,
  FiLock,
  FiRepeat,
} from 'react-icons/fi';
import { IoMdArrowRoundForward } from 'react-icons/io';
import { HiOutlinePuzzle, HiOutlineMail } from 'react-icons/hi';

import writeReviewImg from '../assets/images/write-review-img.svg';

function Sidebar() {
  const sidebarHideHandler = () => {
    document.documentElement.classList.remove('sidebar-active');
  };

  return (
    <>
      <div className="sidebar-overlay" onClick={sidebarHideHandler}></div>
      <aside className="sidebar ">
        <div className="flex flex-col h-full">
          <nav className="flex-1 space-y-3">
            <NavLink
              to="/"
              className="sidebar-link"
              onClick={sidebarHideHandler}
            >
              <FiHome className="sidebar-link-icon" />
              <span>Dashboard</span>
            </NavLink>
            <NavLink
              to="/orders"
              className="sidebar-link"
              onClick={sidebarHideHandler}
            >
              <FiPackage className="sidebar-link-icon" />
              <span>Orders</span>
            </NavLink>
            <NavLink
              to="/back-in-time"
              className="sidebar-link"
              onClick={sidebarHideHandler}
            >
              <FiCalendar className="sidebar-link-icon" />
              <span>Back in Time</span>
            </NavLink>
            <NavLink
              to="/auto-routing"
              href="google.com"
              className="sidebar-link"
              onClick={sidebarHideHandler}
            >
              <FiRepeat className="sidebar-link-icon" />
              <span>Auto Routing</span>
            </NavLink>
            <NavLink
              to="/email-reports"
              className="sidebar-link"
              onClick={sidebarHideHandler}
            >
              <HiOutlineMail className="sidebar-link-icon" />
              <span>Email Reports</span>
            </NavLink>
            <NavLink
              to="/settings"
              className="sidebar-link"
              onClick={sidebarHideHandler}
            >
              <FiSettings className="sidebar-link-icon" />
              <span>Settings</span>
            </NavLink>
          </nav>
          <div className="mt-10">
            {/*
          <a
            href="google.com"
            className="rounded-md bg-video-gradient shadow-md inline-block w-full "
          >
            <div className="w-14 h-14 rounded-md bg-[#fdfeff] relative mx-auto -mt-7">
              <div className="bg-video-gradient rounded absolute inset-1.5 flex items-center justify-center">
                <FaYoutube className="text-white text-xl" />
              </div>
            </div>
            <div className="text-center pt-3 pb-2 px-5 border-b border-white">
              <p className="text-xs font-medium leading-3 text-white">
                Learn how to use Paltrack In less than 2 minutes
              </p>
            </div>
            <div className="text-center">
              <p className="text-sm font-bold text-white pt-4 pb-3">
                Watch tutorial
              </p>
            </div>
          </a>
          */}

            <a
              className="write-review-button group mt-4"
              href="https://apps.shopify.com/paltrack#modal-show=ReviewListingModal"
              target="_blank"
              rel="noreferrer"
            >
              <span>Write a review </span>
              <div className="write-review-icon">
                <img src={writeReviewImg} alt="" />
                <IoMdArrowRoundForward className="w-0 group-hover:w-3.5 transition-all text-white text-lg flex-shrink-0 font-bold" />
              </div>
            </a>

            <div className="mt-7 space-y-3">
              {/* <a href="google.com" className="sidebar-link" onClick={sidebarHideHandler}>
              <FiHelpCircle className="sidebar-link-icon" />
              <span>Help</span>
            </a> */}
              <a
                href="mailto:contact@partnerswire.com"
                className="sidebar-link pr-2 !py-2.5 !bg-[#F3F3FF] hover:!bg-[#E6E6FF] border border-[#E1E1F4]"
              >
                <HiOutlinePuzzle className="sidebar-link-icon" />
                <span className="!ml-2">Request a feature</span>
              </a>
              <a
                href="https://partnerswire.com/privacy-policy/"
                target="_blank"
                to="/privacy"
                rel="noreferrer"
                className="sidebar-link"
                onClick={sidebarHideHandler}
              >
                <FiLock className="sidebar-link-icon" />
                <span>Privacy</span>
              </a>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
}

export default Sidebar;
